import * as React from "react"
import moth from '../images/landing/heremoth.png'
import { Link } from "gatsby"
import "./layout.css"

function Desktop() {
    <div className="here-wrapper">
      <Link to="/home">
        <img className="moth" src={moth} />
      </Link>
    </div>
}

export default function landing() {
  return (
    <>
      <div className="landing-wrapper" >
        <div className="here-wrapper">
          <Link to="/home">
            <img className="moth" src={moth} />
          </Link>
        </div>
        
        <div id="foglayer_01" class="fog">
          <div class="image01"></div>
          <div class="image02"></div>
        </div>
        <div id="foglayer_02" class="fog">
          <div class="image01"></div>
          <div class="image02"></div>
        </div>
        <div id="foglayer_03" class="fog">
          <div class="image01"></div>
          <div class="image02"></div>
        </div>
      </div>

    </>
  )
}
